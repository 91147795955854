







import {
  useFetch,
  defineComponent,
  ref,
  useMeta,
  onMounted,
} from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import { useStoryblokBridge } from '@storyblok/nuxt-2';
import { getMetaInfo, getStoryMeta, getUrlMeta } from '~/helpers/getMetaInfo';
import {
  useUiHelpers,
} from '~/composables';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import { useStoryblokView } from '~/modules/storyblok/composables/useStoryblokView';

export default defineComponent({
  name: 'Home',
  layout: 'basic',
  setup(_props) {
    const story = ref(null);
    const uiHelpers = useUiHelpers();
    const { addTags } = useCache();
    const { isAuthenticated } = useUser();
    const { getStoryBySlug } = useStoryblokView();
    const metaInfo = () => {
      const urlMeta = getUrlMeta(uiHelpers.resolvePath());
      return getStoryMeta({ data: story.value, baseData: urlMeta });
    };

    useMeta(() => getMetaInfo({ page: metaInfo() }));

    useFetch(async () => {
      const { story: sbStory } = await getStoryBySlug({
        slug: 'home',
        redirectOnError: true,
      });
      story.value = sbStory;

      if (!isAuthenticated.value) {
        addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
      }
    });
    onMounted(() => {
      if (story.value && story.value.id) {
        useStoryblokBridge(story.value.id, (evStory) => { (story.value = evStory); }, { preventClicks: true });
      }
    });

    return {
      story,
    };
  },
  head: {},
});
